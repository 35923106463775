import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SEO from '../components/SEO';
import { withQuoteWizard } from '../components/withQuoteWizard';

//Images
import {  DolphinsStadiumImages, SingleProjectsIcons } from '../imageURLs';

//Data
import keyProcesses from '../../data/dolphins-stadium/key-processes.json';
import listItems from '../../data/dolphins-stadium/premium-tiftuf-installation.json';

import CenterContentMain from '../components/CenterContentMain';
import CenterContentNarrow from '../components/CenterContentNarrow';
import InnerImage from '../components/InnerImage/innerImage';
import SectionIcons from '../components/singleproject/sectionIcons';
import IntroBlock from '../components/singleproject/introBlock';
import ProcessText from '../components/singleproject/processText';
import SectionTitle from '../components/SectionTitle';
import TitleSmaller from '../components/TitleSmaller';
import FullWidthCarouselSlider from '../components/Carousel/FullWidthCarouselSlider';
import ListItemsBlock from '../components/ListItemsBlock';
import CtaBlock from '../components/CtaBlock';
import CTAButton from '../components/CTAButton/CTAButton';
import { getElements } from '../helpers/functions';
import { similarProjects } from '../helpers/consts';
import SimilarProjects from '../sections/SimilarProjects/SimilarProjects';


const sliderItems = [
  {
    imagePath: DolphinsStadiumImages.slide1,
  },
  {
    imagePath: DolphinsStadiumImages.slide2,
  },

];

const projectSliderItems = getElements(similarProjects, [1,3,4,5,6,7,8,9])

const styles = theme =>
  createStyles({
    projectWrap: {
      marginBottom: 40,
    },

    introTop: {
      padding: '35px 0',
      [theme.breakpoints.up(767)]: {
        padding: '45px 0',
      },
      maxWidth: '850px',
      margin: '0 auto'
    },

    iconsWrapper: {
      justifyContent: 'center',
    },

    imageSection: {
       [theme.breakpoints.up(960)]: {
          padding: '20px 0px 80px 0',
      },
     },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
     },

    twoColumnsWrap: {
      alignItems: 'flex-start',
      paddingBottom: 50,
      flexWrap: 'wrap',
      paddingTop: 10,
      [theme.breakpoints.up(960)]: {
        flexDirection: 'row',
        paddingBottom: 62,
        paddingTop: 0,
      },
     },

    leftColumnWrap: {
      padding: '40px',
      [theme.breakpoints.up(960)]: {
        flex: '0 0 52%',
        maxWidth: '52%',
        paddingRight: 110,
      },
      [theme.breakpoints.up(1220)]: {
        padding: '40px 120px 40px 0'
      },
     },

    rightColumnWrap: {
      [theme.breakpoints.up(960)]: {
        flex: '0 0 48%',
        maxWidth: '48%',
      },
    },

    rightColumnImage: {
      maxHeight: '506px',
      overflow: 'hidden',
      [theme.breakpoints.up(767)]: {
        maxHeight: '560px'
      },
    },

    sliderWrap: {
      marginTop: 20,
      [theme.breakpoints.up(767)]: {
        marginTop: 45
      },
    },

  });

const DolphinsStadiumPage = ({classes,children}) => (

<React.Fragment>

<SEO
    title="Dolphins Stadium - Twin View Turf - Producer of Australia’s Best Lawns"
    keywords={[
      `Turf care guides`,
      `care guides`,
      `Turf`,
      `Lawns`,
      `Best Lawns`,
      `Australia’s Best Lawns`,
      `lawn producer`,
    ]}
/>

    {/* HERO SECTION START*/}
    <InnerImage 
      image={{className: classes.img, src: DolphinsStadiumImages.hero}}
    ></InnerImage> 

    {/* INTRO SECTION START */}
    <CenterContentMain>
      <Grid
        container
        direction="column"
        className={classes.introTop}
      >
          <SectionTitle
            title="Dolphins Stadium, Redcliffe"
            subTitle="Paynter Dixon"  
          > 
          </SectionTitle>

          <Grid
            container
            className={classes.iconsWrapper }
          >
          <SectionIcons
            iconImage= {SingleProjectsIcons.icon1}
            textAbove= "Service"
            textBelow= "Construction"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon2}
            textAbove= "Type"
            textBelow= "Rugby Field"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon3}
            textAbove= "Turf Type"
            textBelow= "TifTuf"
            ></SectionIcons>

          <SectionIcons
            iconImage= {SingleProjectsIcons.icon4}
            textAbove= "Handover"
            textBelow= "Dec 17"
          ></SectionIcons>
 
          <SectionIcons
            iconImage= {SingleProjectsIcons.icon5}
            textAbove= "Value"
            textBelow= "$240k"
          ></SectionIcons> 
        </Grid>

      </Grid>
    </CenterContentMain>

    <CenterContentNarrow>
          <IntroBlock
            blockText="A complete field reconstruction using premium TifTuf to meet the standards of Australian professional sports leagues."
            value1="9,000m2"
            text1="of premium TifTuf sports turf"
            value2="2,480m"
            text2=" of precision drainage"
            value3="462t"
            text3=" of premium sports blend top soil"
            value4="36"
            text4="games over two days for the 2017 Commonwealth Games"
          >
          </IntroBlock>
    </CenterContentNarrow>

    {/* IMAGE SECTION START */}
      <CenterContentMain>
        <Grid
          container
          className={classes.imageSection}
        >
          <InnerImage
            image={{ className: classes.img, src: DolphinsStadiumImages.feature }}
          ></InnerImage>

        </Grid>
      </CenterContentMain>

    {/* TWO COLUMNS SECTION START */}
      <CenterContentNarrow>
        <Grid
          container
          direction="column"
          className={classes.twoColumnsWrap}
        >
          {/* left column */}
          <Grid
            container
            direction="column"
            className={classes.leftColumnWrap}
          >
            <TitleSmaller
              title="Key Processes"
            ></TitleSmaller>

            {keyProcesses.map(process => (
            <ProcessText
              key={process.title}
              processTitle={process.title}
              processDesc={process.desc}

            />
            ))}
            
            </Grid>
            {/* right column */}
            <Grid
              container
              direction="column"
              className={classes.rightColumnWrap}
            >
              {/* right column-text */}

            <ListItemsBlock
              title="Premium TifTuf Installation"
              text={listItems}
              ctaText="Learn more"
              ctaHref="/sports-turf"
              inverted
            >
            </ListItemsBlock>

            {/* right column image */}

            <Grid
              container
              className={classes.rightColumnImage}
            >
              <InnerImage
                image={{ className: classes.img, src: DolphinsStadiumImages.thumb }} 
              ></InnerImage>
            </Grid>

          </Grid>
        </Grid>
      </CenterContentNarrow>

      {/* CAROUSEL SECTION START */}
      <SectionTitle
        title="The Transformation"
      ></SectionTitle>

      <CenterContentMain>
        <Grid
        container
        className={classes.sliderWrap}
        >
          <FullWidthCarouselSlider largeImage items={sliderItems} />
        </Grid>
      </CenterContentMain>

      <SimilarProjects cards={projectSliderItems} />

    {/* CTA SECTION START */}
    <CtaBlock title="Planning a field construction?">
      <CTAButton
        inverted
        ctaHref="/contact"
        ctaText="Call Us"
      />
    </CtaBlock> 

  {children}
</React.Fragment>

)

export default withQuoteWizard(withStyles(styles, { withTheme: true })(DolphinsStadiumPage));